<template>
    <div class="container">
        <div v-if="messageAlert">
          <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12">
              <dx-data-grid
                id="gridContainer"
                :dataSource="dataSource"
                :allowColumnReordering="true"
                :hoverStateEnabled="true"
                :showColumnLines="true"
                :showRowLines="true"
                :showBorders="true"
                :rowAlternationEnabled="true"
                :selectedRowKeys="[]"
                :height="600"
                :columnAutoWidth="true"
                :allowColumnResizing="true"  
              >
              <dx-toolbar>
                <dx-item
                  location="after"
                  template="refreshTemplate"
                ></dx-item>
                <dx-item
                  location="after"
                  name="exportButton"
                ></dx-item>
                <dx-item
                  location="after"
                  name="columnChooserButton"
                ></dx-item> 
              </dx-toolbar>
              <template #refreshTemplate>
                <dx-button
                  icon="plus"
                  @click="createSubdomain()"
                ></dx-button>
              </template>
                <dx-column-chooser :enabled="true"></dx-column-chooser>
                <dx-export :enabled="true"></dx-export>
                <dx-scrolling row-rendering-mode="virtual"></dx-scrolling>
                <dx-paging :page-size="10"></dx-paging>
                <dx-pager
                  :visible="true"
                  :allowed-page-sizes="pageSizes"
                  :display-mode="displayMode"
                  :show-page-size-selector="showPageSizeSelector"
                  :show-info="showInfo"
                  :show-navigation-buttons="showNavButtons"
                ></dx-pager>  
                <dx-header-filter :visible="true"></dx-header-filter>
                <dx-filter-row :visible="true"></dx-filter-row>
                <dx-column-fixing :enabled="true"></dx-column-fixing>
                <dx-column-chooser
                  :enabled="true"
                  mode="select"
                  :allowSearch="true"
                >
                </dx-column-chooser>
                <dx-selection mode="single"></dx-selection>
                <dx-state-storing 
                  :enabled="false"
                  type="localStorage"
                  storageKey="storage"
                >
                </dx-state-storing>
                <dx-column
                  dataField="id"
                  caption=""
                  :showInColumnChooser="false"
                  :width="50"
                  :allowFiltering="false"
                  :allowSorting="false"
                  cellTemplate="cellEdit"
                  :fixed="true">
                </dx-column>
                <dx-column 
                  dataField="name"
                  :width="180"
                  :minWidth="80"
                  :fixed="true"
                  caption="Nombre">
                </dx-column>
                <dx-column 
                  dataField="url"
                  :width="180"
                  :minWidth="80"
                  :fixed="true"
                  caption="URL">
                </dx-column>
                <dx-column 
                  dataField="version"
                  :width="80"
                  :minWidth="80"
                  :fixed="true"
                  caption="Versión">
                </dx-column>
                <dx-column 
                  dataField="database_host"
                  :width="100"
                  :minWidth="80"
                  :fixed="true"
                  caption="Host">
                </dx-column>
                <dx-column 
                  dataField="database_name"
                  :width="180"
                  :minWidth="80"
                  :fixed="true"
                  caption="Base de datos">
                </dx-column>
                <dx-column 
                  dataField="database_user"
                  :width="80"
                  :minWidth="80"
                  :fixed="true"
                  caption="Usuario DB">
                </dx-column>
                <dx-column 
                  dataField="server"
                  :width="160"
                  :minWidth="80"
                  :fixed="true"
                  caption="Servidor">
                </dx-column>
                <dx-column 
                  dataField="url_backend"
                  :width="160"
                  :minWidth="80"
                  :fixed="true"
                  caption="Prefijo">
                </dx-column>
                <dx-column 
                  dataField="estatus"
                  :width="80"
                  :minWidth="80"
                  :fixed="true"
                  caption="Estatus">
                </dx-column>
                <dx-group-panel :visible="true"></dx-group-panel>
                <template #cellEdit="{ data }"> 
                  <div style="text-align: center;">
                    <a href="#" class="fas fa-pen fa-sm" @click="onEditSubdomain(data)" title="Editar" style="text-decoration:none;"></a>
                    <a href="#" :class="data.data.status==0?'mx-1 fas fa-lock fa-sm':'mx-1 fas fa-unlock fa-sm'" @click="onStatusSubDomain(data)" :title="data.data.status==0?'Activar':'Desactivar'" style="text-decoration:none;"></a>
                    <a href="#" class="fas fa-trash fa-sm" @click="onDeleteSubdomain(data)" title="Eliminar" style="text-decoration:none;"></a>
                  </div>
                </template>
              </dx-data-grid>              
            </div>
        </div>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :closeOnOutsideClick="true"
          :visible="popupVisibleCreate"
          @hidden="onHidden"
        >
          <template #titleModal>
            <h3>{{titleModal}}</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <form action="">
                  <div class="form">
                    <div class="dx-fieldset">
                      <div class="dx-field">
                        <div class="dx-field-label">Nombre</div>
                        <div class="dx-field-value">
                          <dx-text-box v-model="subdomain.name"></dx-text-box>
                          <small style="color:#FF6868;" v-for="(e_name, index) in errors.name" :key="index">{{e_name}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">URL</div>
                        <div class="dx-field-value">
                          <dx-text-box v-model="subdomain.url"></dx-text-box>
                          <small style="color:#FF6868;" v-for="(e_url, index) in errors.url" :key="index">{{e_url}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Versión</div>
                          <div class="dx-field-value">
                          <dx-text-box v-model="subdomain.version"></dx-text-box>
                          <small style="color:#FF6868;" v-for="(e_version, index) in errors.version" :key="index">{{e_version}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Host:</div>
                          <div class="dx-field-value">
                          <dx-select-box
                                :search-enabled="true"
                                :data-source="hosts"
                                :search-mode="searchModeOption"
                                :search-expr="searchExprOption"
                                :search-timeout="searchTimeoutOption"
                                :min-search-length="minSearchLengthOption"
                                :show-data-before-search="showDataBeforeSearchOption"
                                display-expr="name"
                                value-expr="id"
                                v-model="subdomain.database_host"
                                width="200px"
                            ></dx-select-box>
                          <small style="color:#FF6868;" v-for="(e_database_host, index) in errors.database_host" :key="index">{{e_database_host}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Base de Datos</div>
                          <div class="dx-field-value">
                            <dx-text-box v-model="subdomain.database_name"></dx-text-box>
                            <small style="color:#FF6868;" v-for="(e_database_name, index) in errors.database_name" :key="index">{{e_database_name}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Usuario base de Datos</div>
                          <div class="dx-field-value">
                            <dx-text-box v-model="subdomain.database_user"></dx-text-box>
                            <small style="color:#FF6868;" v-for="(e_database_user, index) in errors.database_user" :key="index">{{e_database_user}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Prefijo API</div>
                          <div class="dx-field-value">
                            <dx-text-box v-model="subdomain.url_backend"></dx-text-box>
                            <small style="color:#FF6868;" v-for="(e_url_backend, index) in errors.url_backend" :key="index">{{e_url_backend}}</small>
                        </div>
                      </div>
                      <div class="dx-field">
                        <div class="dx-field-label">Servidor:</div>
                        <div class="dx-field-value">
                            <dx-select-box
                                :search-enabled="true"
                                :data-source="servers"
                                :search-mode="searchModeOption"
                                :search-expr="searchExprOption"
                                :search-timeout="searchTimeoutOption"
                                :min-search-length="minSearchLengthOption"
                                :show-data-before-search="showDataBeforeSearchOption"
                                display-expr="server"
                                value-expr="id"
                                v-model="subdomain.server_id"
                                width="200px"
                            ></dx-select-box>
                            <small style="color:#FF6868;" v-for="(e_servidor, index) in errors.servidor" :key="index">{{e_servidor}}</small>
                        </div>
                      </div>
                      <div  v-if="option=='edit'" class="dx-field" >
                        <div class="dx-field-label">Estatus:</div>
                        <div class="dx-field-value">
                          <div v-if="subdomain.status==1" class="dx-field-label">Activo</div>
                          <div v-if="subdomain.status==0" class="dx-field-label">Inactivo</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  type="normal"
                  text="Cancelar"
                 @click="cancelSubdomain()"
                ></dx-button>
                <dx-button
                  v-if="option=='save'"
                  icon="save"
                  type="default"
                  text="Crear"
                  @click="newSubdomain()"
                ></dx-button>
                <dx-button
                  v-if="option=='edit' && subdomain.status==1"
                  icon="clear"
                  type="danger"
                  text="Inactivar"
                  @click="openModalStatus()"
                ></dx-button>
                <dx-button
                  v-if="option=='edit' && subdomain.status==0"
                  icon="fas fa-unlock fa-sm"
                  type="default"
                  text="Activar"
                  @click="openModalStatus()"
                ></dx-button>
                <dx-button
                  v-if="option=='edit'"
                  icon="fas fa-lock fa-sm"
                  type="default"
                  text="Modificar"
                  @click="updateSubdomain()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :closeOnOutsideClick="true"
          :visible="popupVisibleDelete"
          @hidden="onHiddenDelete"
        >
          <template #titleModal>
            <h3>Eliminar subdominio</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <h3>¿Deseas eliminar al subdominio "{{subdomain_name}}"?</h3>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  type="normal"
                  text="Cancelar"
                 @click="cancelSubdomainDelete()"
                ></dx-button>
                <dx-button
                  icon="trash"
                  type="danger"
                  text="Eliminar"
                  @click="deleteSubdomain()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :closeOnOutsideClick="true"
          :visible="popupVisibleStatus"
          @hidden="onHiddenStatus"
        >
          <template #titleModal>
            <h3>{{titleStatus}} subdominio</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <h3>¿Deseas {{titleStatus}} al subdominio "{{subdomain_name}}"?</h3>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  type="normal"
                  text="Cancelar"
                 @click="cancelSubdomainStatus()"
                ></dx-button>
                <dx-button
                  type="danger"
                  :text="subdomain_status==1?'Desactivar':'Activar'"
                  @click="chageStatus()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
    </div>
</template>
<script>

const hosts = [{
    id: 'localhost',
    name: 'localhost',
  },
  {
    id: '10.0.1.123',
    name: '10.0.1.123',
  }, 
]

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
} from 'devextreme-vue/data-grid';
import {
    DxValidator,
    DxRequiredRule,
    DxCustomRule
} from 'devextreme-vue/validator';
 
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import Notification from "../NotificationComponent";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxItem,
    DxTextArea,
    DxScrolling,
    DxPager,
    DxExport,
    DxHeaderFilter,
    DxFilterRow,
    DxColumnFixing,
    DxColumnChooser,
    DxSelection,
    DxStateStoring,
    DxGroupPanel,
    DxPopup,
    DxButton,
    DxTextBox,
    DxToolbar,
    DxValidator,
    DxRequiredRule,
    DxCustomRule,
    DxCheckBox,
    DxSelectBox,
    DxLoadPanel,
    Notification
  },
  data() {
    return {
      dataSource: [],
      displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
      displayMode: 'full',
      pageSizes: [5, 10, 'all'],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      popupVisibleCreate: false,
      popupVisibleDelete:false,
      popupVisibleStatus:false,
      subdomain:{
        id:"",
        name:"",
        url:"",
        version:"",
        database_host:"",
        database_name:"",
        database_user:"",
        url_backend:"",
        server_id:"",
        status:"",
      },
      subdomain_id:"",
      subdomain_name:"",
      subdomain_status:1,
      titleModal:"",
      option:"save",
      errors:[],
      servers:[],
      hosts:hosts,
      titleStatus:"Activar",
      searchModeOption: 'contains',
      searchExprOption: 'name',
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      isLoadPanelVisible: false,
      messageAlert:0,
    };
  },
  methods: {
    cambiarMsgAlert(value){
      this.messageAlert= value;
    },
    cancelSubdomainStatus(){
      this.popupVisibleStatus=false;
      this.subdomain_id = "";
      this.subdomain_name = "";
    },
    chageStatus(){
      axios.post('banned-subdomain/'+this.subdomain_id).then(response=>{
        this.messageAlert = response.data.message;
        this.popupVisibleStatus=false;
        this.cleanTextBox();
        this.getList();
      });
    },
    openModalStatus(){
      this.popupVisibleCreate =  false;
      this.popupVisibleStatus=true;
      this.subdomain_status = this.subdomain.status;
      this.titleStatus =  this.subdomain.status==1?'Desactivar':'Activar';
      this.subdomain_id = this.subdomain.id;
      this.subdomain_name = this.subdomain.name;
    },
    onHiddenStatus(e){
      this.cancelSubdomainStatus;
    },
    onStatusSubDomain(value){
      this.popupVisibleStatus=true;
      this.subdomain_status = value.data.status;
      this.titleStatus =  value.data.status==1?'Desactivar':'Activar';
      this.subdomain_id = value.value;
      this.subdomain_name = value.data.name;
    },
    deleteSubdomain(){
      axios.delete('subdominios/'+this.subdomain_id).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cancelSubdomainDelete();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					console.log(error);	
				}else{
					console.log(this.errors);
				}
			}); 
    },
    onHiddenDelete(e){
      this.cancelSubdomainDelete();
    },
    cancelSubdomainDelete(){
      this.popupVisibleDelete=false;
      this.subdomain_id = "";
      this.subdomain_name = "";
    },
    onDeleteSubdomain(value){
      this.popupVisibleDelete=true;
      this.subdomain_id = value.value;
      this.subdomain_name = value.data.name;
    },
    createSubdomain(){
      this.cleanTextBox();
      this.showChildModal();
      this.titleModal="Nuevo subdominio"
      this.option="save";
    },
    cancelSubdomain(){
      this.cleanTextBox();
      this.hideChildModal();
      this.errors=[];
    },
    cleanTextBox(){
      this.subdomain.name="";
      this.subdomain.url="";
      this.subdomain.version="";
      this.subdomain.database_host="";
      this.subdomain.database_name="";
      this.subdomain.database_user="";
      this.subdomain.url_backend="";
      this.subdomain.server_id="";
      this.subdomain.status="";
    },
    newSubdomain(){
      axios.post('subdominios', this.subdomain).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    showChildModal(){
      this.popupVisibleCreate =  true;
    },
    hideChildModal(){
      this.popupVisibleCreate =  false;
    },
    onHidden(e) {
      this.cancelSubdomain();
    },
    onEditSubdomain(value){
      this.titleModal="Editar subdominio"
      this.option="edit";
      this.cleanTextBox();
      this.showChildModal();
      this.subdomain_id = value.value;
      this.subdomain.name=value.data.name;
      this.subdomain.id = value.value;
      this.subdomain.url=value.data.url;
      this.subdomain.version=value.data.version;
      this.subdomain.database_host=value.data.database_host;
      this.subdomain.database_name=value.data.database_name;
      this.subdomain.database_user=value.data.database_user;
      this.subdomain.url_backend=value.data.url_backend;
      this.subdomain.server_id=value.data.server_id;
      this.subdomain.status = value.data.status;
    },
    updateSubdomain(){
      axios.put('subdominios/'+this.subdomain_id, this.subdomain).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    getList(){
			axios.get('listsubdomains').then(response=>{
        this.dataSource = response.data.subdomains;
        this.servers = response.data.servers;
      })
    }
  },
  mounted() {
    this.getList();
  },
};
</script>
<style>
#data-grid-demo {
  min-height: 700px;
}
</style>
